import { ForwardRefRenderFunction, forwardRef, useImperativeHandle } from 'react'
import { UserDetails } from 'views/profile-page/types'
import ProfileAvatar from 'views/profile-page/components/ProfileAvatar'
import NameWithVerificationBadge from 'shared/components/display/NameWithVerificationBadge'
import UserAddress from 'shared/components/users/UserAddress'
import AlsoFollowedBySection from './AlsoFollowedBySection'
import FollowAndShareSection from './FollowAndShareSection'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useBoolean } from 'usehooks-ts'
import FollowInfoSection from './FollowInfoSection'

const ProfileCollectorsModal = dynamic(() => import('./CollectorSection/ProfileCollectorsModal'), { ssr: false })
const QRModal = dynamic(() => import('views/profile-page/components/QRModal'))
const ArtistSaleStatistic = dynamic(() => import('views/profile-page/components/ArtistSaleStatistic'), { ssr: false })

type ProfileHeaderProps = {
  profile: UserDetails
  isLoggedIn: boolean
  isCurrentUser: boolean
  // roles
  isUserCollector: boolean
  isUserCreator: boolean
  isUserCA: boolean
  isUserIA: boolean
  isUserHenry: boolean
}

export type ProfileHeaderRef = {
  openCollectorPopup: () => void
  closeCollectorPopup: () => void
}

const ProfileHeader: ForwardRefRenderFunction<ProfileHeaderRef, ProfileHeaderProps> = (props, ref) => {
  const {
    profile,
    isCurrentUser,
    // roles
    isUserCollector,
    isUserCreator,
    isUserCA,
    isUserIA,
    isUserHenry,
  } = props

  const { value: isShowingQRMode, toggle: toggleQRMode } = useBoolean(false)
  const {
    value: isCollectorPopupVisible,
    setFalse: closeCollectorPopup,
    setTrue: openCollectorPopup,
  } = useBoolean(false)

  useImperativeHandle(
    ref,
    () => ({
      openCollectorPopup,
      closeCollectorPopup,
    }),
    [closeCollectorPopup, openCollectorPopup],
  )

  return (
    <>
      <section className="w-full py-6 lg:py-12">
        <div className="container">
          <div className="flex w-full flex-row items-start justify-between gap-4 lg:items-center">
            {/* avatar & bio */}
            <div className="w-full lg:w-1/2">
              <div className="flex w-full flex-col items-start gap-2 lg:flex-row lg:items-center lg:gap-4">
                {/* avatar */}
                <div className="flex w-full justify-between p-2 lg:w-auto lg:p-6">
                  <div className="size-[70px] cursor-pointer lg:size-[140px]">
                    <ProfileAvatar
                      avatar={profile.coverImageMedium}
                      onClick={toggleQRMode}
                      isVerified={profile.isVerified}
                      isEarlyAdopter={profile.isEarlyAdopter}
                      titleStamp={profile.titleStamp}
                      fullname={profile.fullName}
                    />
                  </div>

                  {/* statistic on Mobile */}
                  <div className="inline-flex justify-end lg:hidden">
                    <FollowInfoSection
                      followerCount={profile.followerCount}
                      followingCount={profile.followingCount}
                      username={profile.username}
                      userId={profile.id}
                      // roles
                      isUserCollector={isUserCollector}
                      isUserCreator={isUserCreator}
                      isUserCA={isUserCA}
                      isUserIA={isUserIA}
                      isUserHenry={isUserHenry}
                    />
                  </div>
                </div>
                {/* name & bio */}
                <div className="flex-auto lg:flex-1">
                  <div className="w-full">
                    <div className="w-fit">
                      <NameWithVerificationBadge
                        fullName={profile.fullName}
                        isVerified={profile.isVerified}
                        className="text-base font-semibold text-kokushoku-black lg:text-3xl"
                        maxNameLength={-1}
                      />
                    </div>
                    <h2 className="text-[10px] font-medium text-kokushoku-black/50 lg:text-base">
                      @{profile.username}
                    </h2>
                    <p className="whitespace-pre-line text-[10px] font-semibold capitalize tracking-wide empty:hidden lg:text-base">
                      {profile.subtitle}
                    </p>
                    <UserAddress city={profile.city} state={profile.state} country={profile.country} hideIfEmpty />
                  </div>
                </div>
              </div>
            </div>

            {/* statistic & follow status on Desktop */}
            <div className="hidden w-1/2 pt-0 lg:block">
              <div className="inline-flex h-[51px] w-full justify-center">
                <FollowInfoSection
                  followerCount={profile.followerCount}
                  followingCount={profile.followingCount}
                  username={profile.username}
                  userId={profile.id}
                  // roles
                  isUserCollector={isUserCollector}
                  isUserCreator={isUserCreator}
                  isUserCA={isUserCA}
                  isUserIA={isUserIA}
                  isUserHenry={isUserHenry}
                />
              </div>

              <div className="mt-8 w-full">
                <FollowAndShareSection
                  userId={profile.id}
                  isFollowing={profile.isFollowing}
                  username={profile.username}
                  isCurrentUser={isCurrentUser}
                  fullName={profile.fullName}
                />
              </div>
            </div>
          </div>
          {/* sub statistic & sub follow status */}
          <div className="w-full py-4 lg:py-8">
            <div className="flex flex-col-reverse items-start justify-start gap-2 lg:flex-row lg:items-center lg:justify-between lg:gap-4">
              <div className="h-6 w-full lg:h-7 lg:flex-1">
                {isUserCreator && (
                  <ArtistSaleStatistic
                    userId={profile.id}
                    username={profile.username}
                    onCollectorCountClick={openCollectorPopup}
                  />
                )}
              </div>

              {!isCurrentUser && (
                <div className="flex-1">
                  <Link passHref href={`/u/${profile.username}/mutual`} className="cursor-pointer hover:underline">
                    <AlsoFollowedBySection alsoFollowed={profile.alsoFollowedBy} />
                  </Link>
                </div>
              )}
            </div>

            {/* show FollowAndShareSection on Mobile */}
            <div className="mt-4 w-full lg:hidden">
              <FollowAndShareSection
                userId={profile.id}
                isFollowing={profile.isFollowing}
                username={profile.username}
                isCurrentUser={isCurrentUser}
                fullName={profile.fullName}
              />
            </div>
          </div>
        </div>
      </section>

      <QRModal
        onClose={toggleQRMode}
        userPhoto={profile.coverImage}
        fullName={profile.fullName}
        username={profile.username}
        isVerified={profile.isVerified}
        open={isShowingQRMode}
      />

      {isCollectorPopupVisible && (
        <ProfileCollectorsModal
          username={profile.username}
          onClose={closeCollectorPopup}
          firstName={profile.firstName}
          fullName={profile.fullName}
        />
      )}
    </>
  )
}

export default forwardRef(ProfileHeader)
