import RoundedAvatarImage from 'shared/components/avatar/RoundedAvatarImage'
import CustomImage from 'shared/components/CustomImage'
import { useMemo } from 'react'

type Props = {
  isEarlyAdopter: boolean
  titleStamp?: number
  isVerified: boolean
  fullname: string
  avatar?: string
  onClick: () => void
}

const ProfileAvatar = (props: Props) => {
  const { isEarlyAdopter, titleStamp = 0, isVerified, fullname, avatar, onClick } = props
  const roundedBadgeSrc: string | null = useMemo(() => {
    if (isEarlyAdopter && titleStamp === 3 && !isVerified) {
      return '/images/profile/early-adopter.svg'
    }

    if (titleStamp === 2 || isVerified) {
      return '/images/profile/verified-creator.svg'
    }

    return null
  }, [isEarlyAdopter, isVerified, titleStamp])
  return (
    <div className="relative flex aspect-square h-full w-full items-center justify-center">
      {/* rounded badge */}
      <div className="absolute inset-0 aspect-square h-full w-full origin-center scale-125 empty:hidden">
        {roundedBadgeSrc && (
          <CustomImage
            src={roundedBadgeSrc}
            alt="stamp image"
            fill
            priority
            className="select-none object-cover object-center"
          />
        )}
      </div>

      <RoundedAvatarImage
        alt={fullname}
        src={avatar}
        className="aspect-square h-full w-full rounded-full border border-black/10 shadow-lg"
        onClick={onClick}
        placeholder="blur"
        blurDataURL="/images/placeholder.webp"
        priority
        sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33.333vw, (max-width: 1920px) 25vw"
      />
    </div>
  )
}

export default ProfileAvatar
