import cn from 'classnames'
import useScrollDirectionForWindow from 'hooks/useScrollDirectionForWindow'
import throttle from 'lodash.throttle'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useMemo } from 'react'
import { useBoolean } from 'usehooks-ts'
import {
  CreatorTabBar,
  HenryTabBar,
  CollectorTabBar,
  IndependentAdvisorTabBar,
  CohartAdvisorTabBar,
} from 'views/profile-page/configs'

const distanceFromTop = 365

type Props = {
  username: string
  fullname: string
  isVerified: boolean
  isCurrentUser: boolean
  // role booleans
  isUserHenry: boolean
  isUserCreator: boolean
  isUserCollector: boolean
  isUserIA: boolean
  isUserCA: boolean
  hasArtworks: boolean
}

const ProfileTabBar = (props: Props) => {
  const {
    username,
    fullname,
    isVerified,
    // role check
    isUserHenry,
    isUserCreator,
    isUserCollector,
    isUserCA,
    isUserIA,
    hasArtworks,
  } = props

  const router = useRouter()
  const pathname = router.pathname

  const scrollDirection = useScrollDirectionForWindow()
  const { value: isStickyOnTop, setValue: setIsStickyOnTop } = useBoolean(false)

  useEffect(() => {
    const eventListener = throttle(() => {
      const stickToTop = window.scrollY > distanceFromTop
      setIsStickyOnTop(stickToTop)
    }, 500)

    window.addEventListener('scroll', eventListener)
    return () => window.removeEventListener('scroll', eventListener)
  }, [setIsStickyOnTop])

  const tabBarItems = useMemo(() => {
    if (isUserIA) return IndependentAdvisorTabBar
    if (isUserCA) return CohartAdvisorTabBar
    if (isUserCollector) return CollectorTabBar
    if (isUserCreator) return CreatorTabBar
    if (isUserHenry) return HenryTabBar
    return [] // should never reach here
  }, [isUserIA, isUserCA, isUserCollector, isUserCreator, isUserHenry])

  const shouldShowTabBarItem = (href: string) => {
    if (isUserCollector && href === '/u/[username]/collections' && !hasArtworks) return false // Don't show collection tab if collector has no artworks
    return true
  }

  return (
    <div
      className={cn(
        'sticky z-20 flex h-12 w-full border border-black/10 bg-[#F9F9F9] transition-all lg:h-[84px]',
        scrollDirection === 'up' && 'top-20',
        scrollDirection === 'down' && 'top-0 ',
      )}
    >
      <div className="container flex items-center">
        <div
          className={cn(
            'flex flex-row items-center justify-start lg:justify-center',
            'no-scrollbar relative w-full gap-1 overflow-y-scroll py-2 lg:py-[18px] ',
            'w-full lg:gap-3',
          )}
        >
          {tabBarItems.map((itemConfig) => {
            const { href, icon, content } = itemConfig
            const isActive = href === pathname
            const label =
              typeof content === 'string' ? content : content({ isActive, isStickyOnTop, fullname, isVerified })

            if (!shouldShowTabBarItem(href)) return null
            return (
              <TabBarItem
                key={href}
                href={href.replace(/\[username\]/g, username)}
                isActive={isActive}
                icon={icon}
                content={label}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

type TabBarItemProps = {
  href: string
  isActive: boolean
  icon?: ReactNode
  content: ReactNode
}

const TabBarItem = (props: TabBarItemProps) => {
  const { href, isActive, icon, content } = props
  return (
    <Link
      passHref
      href={href}
      className={cn(
        'flex flex-row items-center gap-2 whitespace-nowrap',
        'px-[12px] py-[6px] lg:px-[20px] lg:py-[10px]',
        'text-[10px] uppercase tracking-wide text-kokushoku-black lg:text-[16px]',
        isActive && 'rounded-[8px] border border-black/10 bg-black/5 font-bold',
      )}
    >
      {/* icon */}
      {icon}
      {/* content | custom content */}
      {content}
    </Link>
  )
}

export default ProfileTabBar
